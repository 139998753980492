import style from '../pages/style/services.module.scss'


import blue05 from '../images/services/plants/blue-05.svg'
import green02 from '../images/services/plants/green-02.svg'

import green04 from '../images/services/plants/green-04.svg'
import blue02 from '../images/services/plants/blue-02.svg'
import blue03 from '../images/services/plants/blue-03.svg'
import red01 from '../images/services/plants/red-01.svg'
import yellow01 from '../images/services/plants/yellow-01.svg'

import green03 from '../images/services/plants/green-03.svg'
import blue01 from '../images/services/plants/blue-01.svg'
import yellow02 from '../images/services/plants/yellow-02.svg'

import blue04 from '../images/services/plants/blue-04.svg'
import green01 from '../images/services/plants/green-01.svg'


// leaf
// 
export const plants = [{
	id: style.green04,
	aos: 'services-green04',
	alt: 'green algae',
	src: green04,
},{
	id: style.blue03,
	aos: 'services-blue03',
	alt: 'blue algae',
	src: blue03,
},{
	id: style.red01,
	aos: 'services-red01',
	alt: 'red algae',
	src: red01,
},{
	id: style.yellow01,
	aos: 'services-yellow01',
	alt: 'yellow algae',
	src: yellow01,
},];

export const plants03 = [{
	id: style.blue02,
	aos: 'services-blue02',
	alt: 'blue algae',
	src: blue02,
},]


export const plants02 = [{
	id: style.green03,
	aos: 'services-green03',
	alt: 'green algae',
	src: green03,
},{
	id: style.blue01,
	aos: 'services-blue01',
	alt: 'blue algae',
	src: blue01,
},{
	id: style.yellow02,
	aos: 'services-yellow02',
	alt: 'blue algae',
	src: yellow02,
},]

export const paralaxPlants = [{
	id: style.blue04,
	src: blue04,
	alt: 'blue algae',
	data: {
		'data-start': 'transform: rotate(-25deg)',
		'data-end': 'transform: rotate(15deg)',
	}
},{
	id: style.green01,
	src: green01,
	alt: 'green algae',
	data: {
		'data-start': 'transform: rotate(-25deg)',
		'data-end': 'transform: rotate(15deg)',
	}
},]


export const paralaxPlants02 = [{
	id: style.blue05,
	src: blue05,
	alt: 'blue algae',
	data: {
		'data-start': 'transform: rotate(-5deg)',
		'data-end': 'transform: rotate(40deg)',
	},
},{
	id: style.green02,
	src: green02,
	alt: 'green algae',
	data: {
		'data-start': 'transform: rotate(0deg)',
		'data-end': 'transform: rotate(0deg)',
	},
},]