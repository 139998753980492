import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

// import Loadable from "react-loadable"
import loadable from '@loadable/component'
import { useViewport } from "../components/utils/Animation"
// import { Watch } from 'scrollmonitor-react'

import Layout from "../components/layout"
import SEO from "../components/seo"

import GridLayout, { GridItem } from '../components/utils/GridLayout'
import Animation from '../components/utils/Animation'

import style from './style/services.module.scss'

import BioSourced from '../components/images/BioSourced'
import VeganProduct from '../components/images/VeganProduct'
import FoodBrand from '../images/services/food-brand.svg'

import { MovingPlant } from './index'

import sustainableAnimation from '../animations/sustainable.json'
import fishAnimation from '../animations/nofish.json'
import organicAnimation from '../animations/organic.json'
import plantAnimation from '../animations/eggs.json'
import tunaAnimation from '../animations/tuna.json'
import basedAnimation from '../animations/plant-based.json'

import Eggs from '../components/images/Eggs'

import { 
	plants,
	plants02,
	plants03,
	paralaxPlants,
	paralaxPlants02,
} from '../plants/services-plants'

// const loader=()=>(<div>Loading...</div>)
// import ParalaxPlant from "../components/utils/ParalaxPlant"
const ParalaxPlant = loadable(() => import("../components/utils/ParalaxPlant"));
// import ParallaxProvider from "../components/utils/Parallax"
const ParallaxProvider = loadable(() => import("../components/utils/Parallax"));

const WatchPlant = function (props) {
	const isInViewport = useViewport(props.id)
	const cb = props.vpCallback || function () { }

	React.useEffect(
		() => { cb(isInViewport) },
		[isInViewport]
	)
	return <MovingPlant {...props} isInViewport={isInViewport} />
}



const ServicesPage = () => {
		const { 
			talkingYaml, 
			servicesYaml
		} = useStaticQuery(graphql`
			{
				talkingYaml {
			    button {
			      link
			      text
			    }
			    title
			  }
		  	servicesYaml {
	  	    vegan {
	  	      button {
	  	        link
	  	        text
	  	      }
	  	      content
	  	      title
	  	    }
	  	    title
	  	    content
	  	    services {
	  	      title
	  	      services {
	  	        content
	  	        title
	  	        img {
                publicURL
              }
	  	      }
	  	    }
	  	    future {
	  	      content
	  	      title
	  	      button {
	  	        link
	  	        text
	  	      }
	  	    }
	  	    brand {
	  	      button {
	  	        link
	  	        text
	  	      }
	  	      content
	  	      title
	  	    }
	  	    alternative {
	  	      content
	  	      title
	  	    }
	  	  }
	  	}
	 	`)

	const [animateBluePlants, setAnimateBluePlants] = useState(false);

	return (
	  <Layout>

	  	<div id = { style.ServicesPage } >
		  	<SEO 
		  		description = "We explore plant-based products, still largely unknown, and we build with you the food innovations of the future."
		  		title="Services" />

		  	{/* Header */}
		  	<div id = { style.ServicesHeader }>

		  		{
		  			plants.map((plant,key) => (
			  		  <MovingPlant
			  		    className = { style.MovingPlant }
			  		    key = { key }
			  		    { ...plant }/>
		  			))
		  		}

		  		<GridLayout>
		  			<GridItem grid = {{
		  				col: { lg: 9, md: 9 },
		  				push: { lg: 1, md: 1 },
		  			}}>
		  				<h1 className = 'title title--h3'>
		  					{ servicesYaml.title }
		  				</h1>
		  				<h2 className = 'title title--pages'>
		  					{ servicesYaml.content }
		  				</h2>

		  			</GridItem>
		  		</GridLayout>

		  		<div className = {`wrapper ${style.Wrapper}`}>

						{
							plants03.map((plant,key) => (
								<WatchPlant
									vpCallback={({ isInViewport }) => isInViewport && !animateBluePlants && setAnimateBluePlants(true)}
									imgClassName = { animateBluePlants ? `aos-manual` : '' }
									className = { style.MovingPlant }
									key = { key }
									{ ...plant } />
							))
						}

		  			<div className = 'grid'>
			  			<GridItem grid = {{
			  				col: { lg: 6, md: 6 },
			  				push: { lg: 5, md: 5 },
			  			}}>
			  				<h2 className = 'title title--h4'>
			  					{ servicesYaml.alternative.title }
			  				</h2>
			  				<p className = 'crps'>
			  					{ servicesYaml.alternative.content }
			  				</p>
			  			</GridItem>
		  			</div>
		  		</div>

		  	</div>

		  	{/* Future */}
		  	<ParallaxProvider>
			  	<div id = { style.Future }>

			  		{ 
			  			paralaxPlants02.map((plant, key) => (
			  			  <ParalaxPlant
			  			    key = { key }
			  			    className = { style.MovingPlant } 
			  			    { ...plant } />
			  			)) 
			  		}

			  		<GridLayout
			  			className = 'grid-flex--center grid-flex--reverse-md'>
			  			
			  			<GridItem 
			  				className = 'grid-flex__item'
			  				grid = {{
			  					col: { lg: 5, md: 5 },
			  				}}>

			  				<div className = { style.AnimationWrapper + ' ' + style.EggsWrapper }>
				  				<Animation
				  					  id = { style.AnimationBased }
				  					  className = { style.Animation }
				  					  height = { 78 }
				  					  width = { 78 }
				  					  animationData = { basedAnimation } />
				  				</div>
			  				<div className = { style.BioSourced }>
			  					<BioSourced
			  						className = { style.BioSourced__img }
			  						alt = { servicesYaml.future.title } />
			  				</div>

			  				<div className = { style.AnimationWrapper + ' ' + style.EggsWrapper }>
			  					<div className = { style.Eggs }>
			  						<Eggs 
			  						  className = { style.EggsImg }
			  						  alt = 'vegan products' />
			  					</div>

			  					<Animation
			  					  id = { style.AnimationPlant }
			  					  className = { style.Animation }
			  					  height = { 144 }
			  					  width = { 144 }
			  					  animationData = { plantAnimation } />

			  					<Animation
			  					  id = { style.AnimationTuna }
			  					  className = { style.Animation }
			  					  height = { 128 }
			  					  width = { 128 }
			  					  animationData = { tunaAnimation } />
			  				</div>

			  			</GridItem>

			  			<GridItem 
			  				className = 'grid-flex__item'
			  				grid = {{
			  					col: { lg: 6, md: 6 },
			  					pull: { lg: 1, md: 1 },
			  				}}>
			  				<h2 className = 'title title--h4'>
			  					{ servicesYaml.future.title }
			  				</h2>
			  				<p 
			  					className = 'crps'
			  					dangerouslySetInnerHTML = {{ __html: servicesYaml.future.content }}/>
			  				<a
			  					className = 'button button--blue'
			  					href = { servicesYaml.future.button.link }>
			  					{ servicesYaml.future.button.text }
			  				</a>
			  			</GridItem>

			  		</GridLayout>
			  	</div>
		  	</ParallaxProvider>

		  	{/* Vegan */}
		  	<div id = { style.Vegan }>

		  		{
		  			plants02.map((plant,key) => (
			  		  <MovingPlant
			  		    className = { style.MovingPlant }
			  		    key = { key }
			  		    { ...plant }/>
		  			))
		  		}

		  		<GridLayout
		  			className = 'grid-flex--center'>
		  			
		  			<GridItem 
		  				className = 'grid-flex__item'
		  				grid = {{
		  					col: { lg: 6, md: 6 },
		  				}}>
		  				<div className = { style.VeganProduct + ' ' + style.AnimationWrapper }>

		  					<Animation
		  					  id = { style.AnimationFish }
		  					  className = { style.Animation }
		  					  height = { 112 }
		  					  width = { 112 }
		  					  animationData = { fishAnimation } />

		  					<Animation
		  					  id = { style.AnimationOrganic }
		  					  className = { style.Animation }
		  					  height = { 120 }
		  					  width = { 120 }
		  					  animationData = { organicAnimation } />

		  					<VeganProduct
		  						className = { style.VeganProduct__img }
		  						alt = { servicesYaml.vegan.title } />
		  				</div>
		  			</GridItem>
		  			
		  			<GridItem 
		  				className = 'grid-flex__item'
		  				grid = {{
		  					col: { lg: 5, md: 5 },
		  					push: { lg: 1, md: 1 },
		  				}}>
		  				<h2 className = 'title title--h4'>
		  					{ servicesYaml.vegan.title }
		  				</h2>
		  				<p 
		  					className = 'crps'
		  					dangerouslySetInnerHTML = {{ __html: servicesYaml.vegan.content }}/>
		  				<a
		  					className = 'button button--blue'
		  					href = { servicesYaml.vegan.button.link }>
		  					{ servicesYaml.vegan.button.text }
		  				</a>
		  			</GridItem>
		  		</GridLayout>
		  	</div>

		  	{/* Brand */}
		  	<div id = { style.Brand }>

		  		<Animation
		  		  id = { style.AnimationSustainable }
		  		  className = { style.Animation }
		  		  height = { 162 }
		  		  width = { 162 }
		  		  animationData = { sustainableAnimation } />

		  		<GridLayout
		  			className = 'grid-flex--center grid-flex--reverse-md'>
		  			
		  			<GridItem 
		  				className = 'grid-flex__item'
		  				grid = {{
		  					col: { lg: 6, md: 6 },
		  				}}>
		  				<div className = { style.FoodBrand }>
		  					<img 
		  						src = { FoodBrand }
		  						alt = { servicesYaml.brand.title } />
		  				</div>
		  			</GridItem>

		  			<GridItem 
		  				className = 'grid-flex__item'
		  				grid = {{
		  					col: { lg: 6, md: 6 },
		  				}}>
		  				<h2 className = 'title title--h4'>
		  					{ servicesYaml.brand.title }
		  				</h2>
		  				<p 
		  					className = 'crps'
		  					dangerouslySetInnerHTML = {{ __html: servicesYaml.brand.content }}/>
		  				<a
		  					className = 'button button--blue'
		  					href = { servicesYaml.brand.button.link }>
		  					{ servicesYaml.brand.button.text }
		  				</a>
		  			</GridItem>
		  		</GridLayout>
		  	</div>

		  	{/* Services */}
		  	<div id = { style.Services }>
		  		<div className = 'wrapper'>
		  			<h2 className = 'title title--h3'>
		  				{ servicesYaml.services.title }
		  			</h2>
		  		</div>

		  		<GridLayout
		  			className = 'grid-flex'>
		  			{
		  				servicesYaml.services.services.map(({id, title, content, img }) => (
		  					<GridItem
		  						key = { id }
		  						className = 'grid-flex__item'
		  						grid = {{ col: { md: 6, lg: 6 } }}>

		  						<div className = { style.service }>
		  							<div className = { style.service__img }>
		  								<img 
		  									src = { img.publicURL }
		  									alt = { title } />
		  							</div>
		  							
		  							<h3 className = 'crps'>
		  								{ title }
		  							</h3>
		  							<ul>
		  								{ 
		  									content.map((li, key) => (
		  										<li key={`li${key}`}>{ li }</li>
		  									)) 
		  								}
		  							</ul>
		  						</div>

		  					</GridItem>
		  				))
		  			}
		  		</GridLayout>

		  	</div>

		  	{/* Cover */}
		  	<div id = { style.Cover } />

		  	{/* Contact us */}
		  	<div id = { style.Hiring }>

		  		{ 
		  			paralaxPlants.map((plant, key) => (
		  			  <ParalaxPlant
		  			    key = { key }
		  			    className = { style.MovingPlant } 
		  			    { ...plant } />
		  			)) 
		  		}

		  		<div className = 'wrapper'>
		  			<h2 className = 'title title--h4'>
		  				{ talkingYaml.title }
		  			</h2>
		  			<a 
		  				href = { talkingYaml.button.link } 
		  				target = '_blank'
		  				rel="noopener noreferrer"
		  				className = 'button button--clear'>
		  				{ talkingYaml.button.text }
		  			</a>
		  		</div>
		  	</div>

	  	</div>
	  </Layout>
	)
}

export default ServicesPage
